import React from "react";
import "./Checkout.css";
import plus from "../../../assets/images/plus.png";
import OrderDetails from "./OrderDetails";
import DeliveryCard from "./DeliveryCard";
import Features from "./Features";
import { useHistory } from "react-router-dom";

const Delivery = (props) => {
  const history = useHistory();
  return (
    <div className="Checkout">
      <div className="checkout-container container">
        <div className="item-col">
          <div className="cart">
            <div className="cart-count">Delivery</div>
            {(!(props.store?.AddressList?.length >= 5) || true) && (
              <div
                className="add-new"
                onClick={() => {
                  history.push("/shipping-details");
                }}
              >
                <img src={plus} className="img-plus" />{" "}
                {props.store?.AddressList?.length > 0
                  ? "ADD NEW ADDRESS"
                  : "ADD ADDRESS"}
              </div>
            )}
          </div>
          <DeliveryCard />
        </div>
        <div className="bill-col">
          <OrderDetails />
        </div>
      </div>
      <div className="container">
        <Features />
      </div>
    </div>
  );
};
export default connect(Delivery);

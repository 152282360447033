import React, { useState } from "react";
import "./Checkout.css";
import plus from "../../../assets/images/plus.png";
import OrderDetails from "./OrderDetails";
import Features from "./Features";
import { useHistory } from "react-router-dom";
import PaymentCard from "./PaymentCard";
const Payment = () => {
  const history = useHistory();
  return (
    <div className="Checkout">
      <div className="checkout-container container">
        <div className="item-col">
          <div className="cart">
            <div className="cart-count">Delivery</div>
            <div
              className="add-new"
              onClick={() => {
                history.push("/shipping-details");
              }}
            >
              <img src={plus} className="img-plus" /> ADD NEW ADDRESS
            </div>
          </div>
          <PaymentCard />
        </div>
        <div className="bill-col">
          <OrderDetails />
        </div>
      </div>
      <div className="container">
        <Features />
      </div>
    </div>
  );
};
export default Payment;

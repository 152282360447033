import i from "../../../assets/images/i.png";
import car from "../../../assets/images/carrier.png";
import returnpolicy from "../../../assets/images/return.png";
import help from "../../../assets/images/help.png";
import email from "../../../assets/images/email.png";
import { useHistory } from "react-router-dom";
export default connect((props) => {
  const history = useHistory();
  return (
    <div className="features-container">
      <div
        className="features-container-column"
        onClick={() => {
          history.push("/delivery-returns");
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div className="img-container">
          <img src={car} />
        </div>
        <div className="title">Delivery</div>
        <div className="des">
          Each book is printed especially for you and takes 7-9 days to make.
          Delivery time is added on top.
        </div>
      </div>
      <div
        className="features-container-column"
        onClick={() => {
          history.push("/delivery-returns");
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div className="img-container">
          <img src={returnpolicy} />
        </div>
        <div className="title">Returns</div>
        <div className="des">
          Our books are wonderfully personalised so we can't always offer
          refunds or exchanges. However, you'll have a couple of hours after
          ordering to review and make edits.
        </div>
      </div>
      <div
        className="features-container-column"
        onClick={() => {
          history.push("/faq");
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div className="img-container">
          <img src={help} />
        </div>
        <div className="title">Help</div>
        <div className="des">
          Send us a message or find an answer in our FAQs.
        </div>
      </div>
    </div>
  );
});

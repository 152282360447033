import React, { useState, useEffect } from "react";
import style from "./Description.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useParams } from "react-router-dom";
import "./Decription.css";
import { productdetails } from "../productlist/staticsproduct";
const Description = () => {
  let { id } = useParams();
  const [expand, setExpand] = useState("null");
  const [bookDetails, setBookDetails] = useState([]);
  useEffect(() => {
    if (id) {
      getBookDetails(id);
    }
  }, []);
  const getBookDetails = (id) => {
    if (id == 5) {
      let data = productdetails;
      setBookDetails(data.bookDetails);
    } else {
      api.getBookDetails(id).then((res) => {
        if (res.statusCode === 200 && res.data && res.data.bookDetails) {
          setBookDetails(res.data.bookDetails);
        }
      });
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand(isExpanded ? panel : false);
  };
  return (
    <div className={style["main-container"]}>
      {/* <div className={style["title"]}>Book Description</div> */}
      <div className={style["accordion"]}>
        {bookDetails &&
        bookDetails.customHow &&
        bookDetails.customHow["0"].length > 0 ? (
          <Accordion
            className="description"
            id="accordionAboutUs"
            expanded={expand === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={expand === "panel1" ? <RemoveIcon /> : <AddIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={style["subheading"]}>
                How is the book customised?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {bookDetails &&
                bookDetails.customHow &&
                bookDetails.customHow.map((item, key) => {
                  return <div key={key}>{item}</div>;
                })}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {bookDetails &&
          bookDetails.customHow &&
          bookDetails.customHow["0"].length > 0 && <br></br>}
        {bookDetails &&
        bookDetails.book &&
        bookDetails.book.aboutBook &&
        bookDetails.book.aboutBook != "null" ? (
          <Accordion
            className="description"
            expanded={expand === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={expand === "panel2" ? <RemoveIcon /> : <AddIcon />}
              aria-controls="panel2a-content"
              id="panel2"
            >
              <Typography className={style["subheading"]}>
                About the Book
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    (bookDetails &&
                      bookDetails.book &&
                      bookDetails.book.aboutBook &&
                      bookDetails.book.aboutBook.replace(
                        /(\r\n|\n|\r)/gm,
                        "<br>"
                      )) ||
                    "",
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {bookDetails &&
          bookDetails.book &&
          bookDetails.book.aboutBook &&
          bookDetails.book.aboutBook != "null" && <br></br>}
        {bookDetails &&
        bookDetails.book &&
        bookDetails.book.booksAuthor &&
        bookDetails.book.booksAuthor.author &&
        bookDetails.book.booksAuthor.author.authorInfo &&
        bookDetails.book.booksAuthor.author.authorInfo != "null" ? (
          <Accordion
            className="description"
            expanded={expand === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={expand === "panel3" ? <RemoveIcon /> : <AddIcon />}
              aria-controls="panel2a-content"
              id="panel3"
            >
              <Typography className={style["subheading"]}>
                About the Author
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    (bookDetails &&
                      bookDetails.book &&
                      bookDetails.book.booksAuthor &&
                      bookDetails.book.booksAuthor.author &&
                      bookDetails.book.booksAuthor.author.authorInfo &&
                      bookDetails.book.booksAuthor.author.authorInfo.replace(
                        /(\r\n|\n|\r)/gm,
                        "<br>"
                      )) ||
                    "",
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {bookDetails &&
          bookDetails.book &&
          bookDetails.book.booksAuthor &&
          bookDetails.book.booksAuthor.author &&
          bookDetails.book.booksAuthor.author.authorInfo &&
          bookDetails.book.booksAuthor.author.authorInfo != "null" && <br></br>}
        {bookDetails &&
        bookDetails.book &&
        bookDetails.book.booksArtist &&
        bookDetails.book.booksArtist.artist &&
        bookDetails.book.booksArtist.artist.authorInfo &&
        bookDetails.book.booksArtist.artist.authorInfo != "null" ? (
          <Accordion
            className="description"
            expanded={expand === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={expand === "panel4" ? <RemoveIcon /> : <AddIcon />}
              aria-controls="panel2a-content"
              id="panel4"
            >
              <Typography className={style["subheading"]}>
                About the Artist
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    (bookDetails &&
                      bookDetails.book &&
                      bookDetails.book.booksArtist &&
                      bookDetails.book.booksArtist.artist &&
                      bookDetails.book.booksArtist.artist.authorInfo &&
                      bookDetails.book.booksArtist.artist.authorInfo.replace(
                        /(\r\n|\n|\r)/gm,
                        "<br>"
                      )) ||
                    "",
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        ) : null}{" "}
        {bookDetails &&
          bookDetails.book &&
          bookDetails.book.booksArtist &&
          bookDetails.book.booksArtist.artist &&
          bookDetails.book.booksArtist.artist.authorInfo &&
          bookDetails.book.booksArtist.artist.authorInfo != "null" && <br></br>}
        {bookDetails &&
        bookDetails.otherInfo &&
        bookDetails.otherInfo != "null" ? (
          <Accordion
            className="description"
            expanded={expand === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={expand === "panel5" ? <RemoveIcon /> : <AddIcon />}
              aria-controls="panel2a-content"
              id="panel5"
            >
              <Typography className={style["subheading"]}>
                Size & Quality
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    (bookDetails &&
                      bookDetails.otherInfo &&
                      bookDetails.otherInfo.replace(
                        /(\r\n|\n|\r)/gm,
                        "<br>"
                      )) ||
                    "",
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </div>
    </div>
  );
};
export default Description;

import React, { useState, useEffect } from "react";
import "./Checkout.css";
import ProductCard from "./ProductCard";
import BillingAddress from "./BillingAddress";
import arrow from "../../../assets/images/arrow.png";
import plus from "../../../assets/images/plus.png";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SaveForLater from "./SaveForLater";
import SavedBook from "./Savedbook";
const Checkout = (props) => {
  const history = useHistory();
  const [cartDetails, setcartDetails] = useState([]);
  const [saveLater, setSaveLater] = useState([]);
  const [savedBook, setSavedBook] = useState([]);
  const [loading, setLoading] = useState("none");
  useEffect(() => {
    getCartDetails();
    getAllSaveForLaterDetails();
    getAllPromotionList();
    localStorage.setItem("editmode", false);
    mixins.setStore("fulladdress", {}, props);
    mixins.setStore("contrywithgst", {}, props);
    mixins.setStore("gstamount", "", props);
    mixins.setStore("book.worldbookPreInfo", {}, props);
  }, []);
  const getCartDetails = () => {
    setLoading("flex");
    api.getCartDetails().then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200 && res.data) {
        //console.log("Cartmessage", res);
        setcartDetails(res.data);
        mixins.setStore("cartItem", res.data.totalNumberOfBooks, props);
        mixins.setStore("cart_list", res.data, props);
      }
    });
  };
  const getAllSaveForLaterDetails = () => {
    api.getAllSaveforLater().then((res) => {
      if (res && res.statusCode === 200 && res.data) {
        let respData = res.data.saveForLater;
        let respData2 = res.data.savedBooks;
        // console.log(respData);
        setSaveLater(respData);
        setSavedBook(respData2);
        mixins.setStore("seaveforlaterCount", res.data.length, props);
      }
    });
  };
  const getAllPromotionList = () => {
    api.getPromotionList().then((res) => {
      if (res && res.statusCode === 200 && res.data) {
        mixins.setStore("PromotionList", res.data, props);
      }
    });
  };
  return (
    <div className="Checkout">
      <div className="checkout-container container">
        <div className="item-col">
          <div className="cart">
            <div className="cart-count">
              Your Cart ( {cartDetails && cartDetails.totalNumberOfBooks} ) |{" "}
              <span>
                CONTINUE SHOPPING <img src={arrow} className="img" />
              </span>
            </div>
            <div
              className="add-new"
              onClick={() => {
                history.push("/");
              }}
            >
              <img src={plus} className="img-plus" />
              ADD NEW BOOK
            </div>
          </div>
          <div style={{ display: loading, margin: "auto" }}>
            <CircularProgress color="success" />
          </div>
          {cartDetails && cartDetails.cart && cartDetails.cart.length >= 1 ? (
            cartDetails.cart &&
            cartDetails.cart.map((item, index) => {
              return (
                <ProductCard
                  item={item}
                  key={index}
                  getCartDetails={getCartDetails}
                  getAllSaveForLaterDetails={getAllSaveForLaterDetails}
                />
              );
            })
          ) : (
            <div className="cart-empty">Your Cart is Empty</div>
          )}
        </div>
        <div className="bill-col">
          <BillingAddress props={cartDetails} />
        </div>
      </div>
      <div className="save-later-div">
        {saveLater &&
          saveLater &&
          saveLater.length >= 1 &&
          saveLater.length >= 1 && <div className="title">Save For later</div>}
        {saveLater &&
          saveLater &&
          saveLater.length >= 1 &&
          saveLater.map((item, index) => {
            return (
              <SaveForLater
                item={item}
                key={index}
                getCartDetails={getCartDetails}
                getAllSaveForLaterDetails={getAllSaveForLaterDetails}
              />
            );
          })}
      </div>
      <div className="save-later-div">
        {savedBook && props.store.user && savedBook.length >= 1 && (
          <div className="title">Saved Books</div>
        )}
        {savedBook &&
          props.store.user &&
          savedBook.length >= 1 &&
          savedBook.map((item, index) => {
            return (
              <SavedBook
                item={item}
                key={index}
                getCartDetails={getCartDetails}
                getAllSaveForLaterDetails={getAllSaveForLaterDetails}
              />
            );
          })}
      </div>
    </div>
  );
};
export default connect(Checkout);

import { Fragment, useEffect, useState } from "react";
import style from "./Footer.module.css";
// import facebookIcon from "../../../assets/images/facebook_icon.png";
// import twitterIcon from "../../../assets/images/twitter_icon.png";
// import pintrestIcon from "../../../assets/images/pintrest_icon.png";
// import instagramIcon from "../../../assets/images/instagram_icon.png";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment/moment";
const Footer = () => {
  const history = useHistory();
  const { handleSubmit, register } = useForm({ mode: "onBlur" });
  const [content, setContent] = useState("");
  const [cmsList, setCMSList] = useState([]);
  const [email, setEmail] = useState("");
  const [cmsinfo, setcmsinfo] = useState({});
  const [alert, setAlert] = useState(null);
  const [alertFail, setAlertFail] = useState(null);
  const getCMS = () => {
    api.getCMSList().then((res) => {
      if (res.statusCode === 200) {
        setCMSList(res.data);
      }
    });
  };
  const getcmsinfo = () => {
    api.getcmsInfo().then((res) => {
      if (res && res.data) {
        let ret = res.data.reduce((ov, nv) => {
          ov[nv.cmsPages.type] = nv;
          return ov;
        }, {});

        setcmsinfo(ret);
      }
    });
  };
  useEffect(() => {
    getCMS();
    getcmsinfo();
  }, []);
  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setContent(data.copyrightContent))
      .catch((err) => console.error("Failed to load content:", err));
  }, []);
  const OnHandleclickSubcribe = () => {
    if (email) {
      let data = {
        auth_token: "0d17554bc3a146ebad2edbfbe980a176",
        list_id: "789398",
        merge_fields: {
          subscriber: "Newsletter",
          email: email,
        },
      };

      api
        .acumbaNewsletter(data)
        .then((res) => {
          if (res.subscriber_id) {
            setAlert("You have successfully subscribed for the newsletter.");
            setTimeout(() => {
              setAlert(null);
              setEmail("");
            }, 2000);
          } else {
            setAlertFail(res.error);
            setTimeout(() => {
              setAlertFail(null);
              // setEmail("");
            }, 2000);
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
      // api
      //   .postNewsLetter({
      //     type: "newsletter",
      //     email: email,
      //   })
      //   .then((res) => {
      //     if (res && res.statusCode === 200) {
      //       setAlert(res.message);

      //       setTimeout(() => {
      //         setAlert(null);
      //       }, 2000);
      //       setTimeout(() => {
      //         setEmail("");
      //       }, 2000);
      //     } else {
      //       setAlertFail(res.message);
      //       setTimeout(() => {
      //         setAlertFail(null);
      //       }, 2000);
      //     }
      //   });
    }
  };
  return (
    <Fragment>
      <div className={style["container"]}>
        <div className="container p-5">
          <div className="row">
            <div className={"col-12 col-lg-3"}>
              <div className={style["title"]}>PLOTTERBOX</div>
              <div className={style["address"]}>
                <div className={style["addressicon"]}>
                  {" "}
                  <i className="fa fa-map-marker-alt" aria-hidden="true"></i>
                </div>
                <div>
                  {cmsinfo &&
                    cmsinfo["Address"] &&
                    cmsinfo["Address"].description && (
                      <div
                        className={style["footer-item"]}
                        dangerouslySetInnerHTML={{
                          __html: cmsinfo["Address"].description,
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className={"col-12 col-lg-3"}>
              <div className={style["title"]}>QUICK LINKS</div>
              {cmsList.map((data, index) => {
                if (data.display === 1) {
                  return (
                    // <div>{console.log(data)}</div>
                    <li
                      className={style["footer-list-item"]}
                      key={index}
                      onClick={() => history.push(data.url)}
                    >
                      <a href={data.url}>{data.type}</a>
                    </li>
                  );
                }
              })}
            </div>

            {/* <div className={"col-12 col-lg-3"}>
                            <div className={style["title"]}>Updates</div>
                            {cmsinfo && cmsinfo["Updates"] && cmsinfo["Updates"].description &&
                                <div className={style["footer-item"]} dangerouslySetInnerHTML={{ __html: cmsinfo["Updates"].description }} />
                            }
                        </div> */}
            <div className={"col-12 col-lg-3"}>
              <form onSubmit={handleSubmit(OnHandleclickSubcribe)}>
                <div className={style["title"]}>JOIN OUR NEWSLETTER</div>
                <div className={style["footer-item"]}>
                  <div className={style["emailcontainer"]}>
                    <div className={style["emailicon"]}>
                      {/* <i className="fas fa-envelope"></i> */}
                    </div>
                    <input
                      className={style["input"]}
                      placeholder="Your email address"
                      name={email}
                      value={email}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style["footer-item"]}>
                  {alert && (
                    <Alert
                      severity="success"
                      variant="filled"
                      style={{ width: "100%" }}
                    >
                      <strong>{alert}</strong>
                    </Alert>
                  )}
                  {alertFail && (
                    <Alert
                      severity="error"
                      variant="filled"
                      style={{ width: "100%" }}
                    >
                      <strong>{alertFail}</strong>
                    </Alert>
                  )}
                  <button type="submit" className={style["button"]}>
                    SUBSCRIBE
                  </button>
                </div>
              </form>
              {/* <div className={style["socialmediaicons"]}>
                                <img src={facebookIcon} />
                                <img src={twitterIcon} className={style["iconstyle"]} />
                                <img src={instagramIcon} className={style["iconstyle"]} />
                                <img src={pintrestIcon} className={style["iconstyle"]} />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={style["bottom-footer"]}>
        <i className="far fa-copyright"></i>
        {moment().format("YYYY")} copyright Plotterbox
      </div>
    </Fragment>
  );
};
export default Footer;

import React, { useState } from "react";
import "./modal.css";
import { Modal } from "react-bootstrap";

const Alertmessage = ({ open, handleyess, handleno }) => {
  return (
    <Modal
      size="sm"
      show={open}
      //onHide={handleClose}
      animation={true}
      className="custom-modal alert-modal"
      style={{
        zIndex: "999999",
        marginTop: "30px",
      }}
    >
      <p
        className="text-center"
        style={{
          fontWeight: "600",
        }}
      >
        You did not save the book, do you really want to left?
      </p>
      <div className="d-flex align-items-center justify-content-center ">
        <button
          onClick={() => {
            handleyess();
          }}
          className="action-button-previous"
          style={{
            background: "#50a1a4",
            border: "none",
            color: "#fff",
            fontSize: "15px",
            padding: "6px 12px",
            marginRight: "10px",
          }}
        >
          Yess
        </button>
        <button
          onClick={() => {
            handleno();
          }}
          className="action-button-previous"
          style={{
            background: "#fff",
            border: "#50a1a4",
            color: "#50a1a4",
            fontSize: "15px",
            padding: "6px 12px",
          }}
        >
          No
        </button>
      </div>
    </Modal>
  );
};

export default Alertmessage;

import React, { useEffect, useState } from "react";
import "./HistoryChanges.css";
import royalty from "../../../assets/images/royalty.png";
import booksclose from "../../../assets/images/booksclose.png";
import books from "../../../assets/images/books.png";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function HistoryChanges(props) {
  const history = useHistory();
  const [orderHistory, setOrderHistory] = useState([]);
  const [savedBooks, setSavedBooks] = useState([]);
  const [saveForLater, setSaveForLater] = useState([]);
  const [loading, setLoading] = useState("none");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    orderHistoryDetails();
    savedBooksDetails();
  }, []);

  // const orderHistoryDetails = () => {
  //   api.orderHistory().then((res) => {
  //     console.log("orderHistory", res);
  //     if (res && res.data && res.data.orders) setOrderHistory(res.data.orders);
  //   });
  // };
  const orderHistoryDetails = () => {
    api.orderHistory().then((res) => {
      //console.log("orderHistory", res);
      if (res && res.data && res.data.orders) {
        const filterOrderHistory = res.data.orders.filter(
          (item) => item.cart != null && item.cart.length
        );
        //console.log(filterOrderHistory);
        setOrderHistory(filterOrderHistory);
      }
    });
  };
  const savedBooksDetails = () => {
    api.getAllSaveforLater().then((res) => {
      //console.log("SavedBook", res.data);
      let savedBooks = res.data.savedBooks;
      let saveforlater = res.data.saveForLater;
      //console.log(saveforlater);
      if (res && res.data) setSavedBooks(savedBooks);
      setSaveForLater(saveforlater);
    });
  };
  const deleteCartitem = (id) => {
    //console.log(id);
    setLoading("flex");
    api.deleteSubscriptionItem(id).then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200) {
        api.getAllSaveforLater().then((res) => {
          mixins.setStore("seaveforlaterCount", res.data.length, props);
        });
        mixins.reFectchSavedBookDetails(props);
        savedBooksDetails();
      }
    });
  };
  const deleteSaveForlater = (item) => {
    let subId = item.subscription[0].id;
    setLoading("flex");
    api.deleteSubscriptionItem(subId).then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200) {
        api.getAllSaveforLater().then((res) => {
          mixins.setStore("seaveforlaterCount", res.data.length, props);
        });
        mixins.reFectchSavedBookDetails(props);
        savedBooksDetails();
      }
    });
  };
  const movetoCartitem = (item) => {
    let id = item.id;
    setLoading("flex");
    api
      .movetocart(id, {
        customBookId: item?.customProduct?.customBookId,
      })
      .then((res) => {
        setLoading("none");
        if (res && res.statusCode === 200) {
          api.getCartDetails().then((res) => {
            if (res && res.statusCode === 200 && res.data) {
              //console.log("Cartmessage", res);
              mixins.setStore("cartItem", res.data.totalNumberOfBooks, props);
              mixins.setStore("cart_list", res.data, props);
            }
          });
          api.getAllSaveforLater().then((res) => {
            mixins.setStore("seaveforlaterCount", res.data.length, props);
          });
          mixins.reFectchSavedBookDetails(props);
          savedBooksDetails();
          history.push("/checkout-page");
        }
      });
  };

  return (
    <div className="dashboard">
      <div className="content">
        <div className="maincontainer">
          <div className="leftcontainer">
            <div className="left-container-order">
              <div className="order-col">
                <div className="order-col-row">
                  <img src={books} className="royalty" />
                  {/* <img src={i} className="i-white" /> */}
                </div>
                <div className="order-row">
                  {/* <span>${royaltiesEarned.royaltyEarned}</span> */}
                </div>
                <div className="order-col-row">
                  <div className="order-row">Order History</div>

                  {orderHistory &&
                    orderHistory !== undefined &&
                    orderHistory.slice(0, 4).map((item) => {
                      return (
                        <div className="list-order">
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                <span>TotalAmount : {item.grandTotal} SGD</span>
                                <span>
                                  {" "}
                                  OrderDate :
                                  {moment(item.orderDate)
                                    .utc()
                                    .format("YYYY-MM-DD")}
                                </span>
                                <div>
                                  <span>
                                    PaymentMethod : {item.paymentType}
                                  </span>
                                </div>

                                {/* <label>{item.cart.product?.currency}</label>
                          <label>{item.cart.product?.created_at}</label> */}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                {item.shipping.map((o) => {
                                  return (
                                    <div>
                                      <span>
                                        ShippingAddress :
                                        {o.shippingAddress.address1},{" "}
                                        {o.shippingAddress.address2},{" "}
                                        {o.shippingAddress.city},{" "}
                                        {o.shippingAddress.country},{" "}
                                      </span>
                                      <br />
                                      <span>
                                        ContactNum :
                                        {o.shippingAddress.contactNo},{" "}
                                      </span>
                                      <br />
                                      <span>
                                        PinCode :{o.shippingAddress.zipCode}.{" "}
                                      </span>
                                      <br />
                                      <span>
                                        ShippingType :
                                        {o.deliveryType.shippingType},{" "}
                                      </span>
                                      <span>
                                        DeliveryIn :
                                        {o.deliveryType.deliveryTime} days
                                      </span>
                                    </div>
                                  );
                                })}

                                {item.cart.map((bookdetails) => {
                                  return (
                                    <div className="booklist">
                                      <img
                                        src={bookdetails.product?.bookImageUrl}
                                      />
                                      <label>
                                        {bookdetails.product?.bookTitle}
                                      </label>
                                    </div>
                                  );
                                })}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    })}
                  {/* <img src={arrowwhite} className="i-white" /> */}
                </div>
              </div>

              <div className="saved-col">
                <div className="saved-col-row">
                  <img src={booksclose} className="royalty-saved" />
                  {/* <img src={i} className="i-white" /> */}
                </div>
                <div className="saved-row">
                  {/* <span>{royaltiesEarned.booksSold}</span> */}
                </div>
                <div className="saved-col-row">
                  {savedBooks && <div className="saved-row">Saved Books</div>}
                  <div style={{ display: loading }}>
                    <CircularProgress color="success" />
                  </div>
                  {savedBooks &&
                    savedBooks.map((item) => {
                      return (
                        <div className="list">
                          <div className="rightImage">
                            <img src={item.Books?.fileData?.filePath} />
                          </div>
                          <div className="saveDesign">
                            <div className="product">
                              <label>{item.Books?.bookTitle}</label>
                              <label>{item.Books?.bookPrice}</label>
                              <label>SGD</label>
                            </div>
                            <div className="actionButton">
                              <Button
                                className="edit"
                                onClick={() => {
                                  //handprint, customcharacter, chapterbook, picturebook
                                  //console.log(props.item);
                                  mixins.setStore(
                                    "cartdetails",
                                    props.item,
                                    props
                                  );
                                  let section = "chapter-book";
                                  let { customizedId, customBookType } =
                                    item.customBook;
                                  let { customBookId, bookId } = item;
                                  switch (customBookType) {
                                    case "chapterbook":
                                      section = "chapter-book";
                                      break;
                                    case "picturebook":
                                      section = "picture-book";
                                      break;
                                    case "handprint":
                                      section = "handprint-book";
                                      break;
                                    case "customcharacter":
                                      section = "customcharacter-book";
                                    case "worldbook":
                                      section = "world-book";
                                      break;
                                  }
                                  history.push(
                                    `/book/${section}/${bookId}/${customizedId}`
                                  );
                                }}
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                              <Button
                                className="edit"
                                onClick={() => {
                                  deleteCartitem(item.id);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {saveForLater && (
                    <div className="saved-row">Save For Later</div>
                  )}
                  {saveForLater &&
                    saveForLater.map((item) => {
                      return (
                        <div className="list">
                          <div className="rightImage">
                            <img src={item.product?.bookImageUrl} />
                          </div>
                          <div className="saveDesign">
                            <div className="product">
                              <label>{item.product?.bookTitle}</label>
                              <label>{item.product?.actualPrice}</label>
                              <label>SGD</label>
                            </div>
                            <div className="actionButton">
                              <Button
                                className="edit"
                                onClick={() => movetoCartitem(item)}
                              >
                                <i className="fa fa-cart-plus"></i>
                              </Button>
                              <Button
                                className="edit"
                                onClick={() => {
                                  deleteSaveForlater(item);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {/* <img src={arrowwhite} className="i-white" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(HistoryChanges);

import React, { useEffect, useState } from "react";
import Addaddresscard from "./addresspartials/Addaddresscard";
import car from "../../../assets/images/tipper.png";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";

const AddressCard = (props) => {
  const { handleSubmit } = useForm({ mode: "onBlur" });
  const [subscribe, setSubscribe] = useState(0);
  const [alert, setAlert] = useState(null);
  const history = useHistory();
  const { code } = useParams();
  const [defaults, setDefaults] = useState(0);
  const [sameAdd, setSameadd] = useState(true);
  const [countrylist, setCountrylist] = useState([]);
  const [email, setEmail] = useState("");

  const [shippingAddress, setShippingaddress] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    address1: "",
    address2: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });
  const [billingAddress, setBillingaddress] = useState({
    address1: "",
    address2: "",
    city: "",
    contactNo: "",
    country: "",
    firstName: "",
    lastName: "",
    state: "",
    zipCode: "",
  });

  const onSubmit = () => {
    let editstatus = false;
    if (props.store?.BillingAddress?.id) {
      editstatus = mixins.deepEqual(
        billingAddress,
        props.store?.BillingAddress
      );
    }

    let billingadress = sameAdd ? shippingAddress : billingAddress;
    console.log(sameAdd, shippingAddress, billingadress);
    if (shippingAddress.firstName == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("First name is required.");
    } else if (shippingAddress.lastName == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Last name is required.");
    } else if (shippingAddress.address1 == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("House number/ Apt street is required.");
    } else if (shippingAddress.country == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Country is required.");
    } else if (shippingAddress.state == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("State is required.");
    } else if (shippingAddress.city == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("City is required.");
    } else if (shippingAddress.zipCode == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Postcode is required.");
    } else if (shippingAddress.contactNo == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Contact Number is required.");
    } else if (billingadress.firstName == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address first name is required.");
    } else if (billingadress.lastName == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address last name is required.");
    } else if (billingadress.address1 == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address house number/ Apt. street is required.");
    } else if (billingadress.country == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address country is required.");
    } else if (billingadress.state == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address state is required.");
    } else if (billingadress.city == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address city is required.");
    } else if (billingadress.zipCode == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address postal is required.");
    } else if (billingadress.contactNo == "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setAlert("Billing address contact number is required.");
    } else {
      //API Call
      if (code) {
        api
          .updateShipingAddress(code, {
            firstName: shippingAddress?.firstName,
            lastName: shippingAddress?.lastName,
            email: email,
            contactNo: shippingAddress?.contactNo,
            address1: shippingAddress?.address1 || "",
            address2: shippingAddress?.address2 || "",
            landmark: "",
            city: shippingAddress?.city || "",
            state: shippingAddress?.state || "",
            country: shippingAddress?.country,
            zipCode: shippingAddress?.zipCode,
            default: defaults,
            subscribe: +subscribe,
            // Billing
            billEditstatus: editstatus,
            billId: billingadress?.id || null,
            billaddress1: billingadress?.address1 || "",
            billaddress2: billingadress?.address2 || "",
            billcity: billingadress?.city,
            billstate: billingadress?.state,
            billcountry: billingadress?.country,
            billfirstname: billingadress?.firstName,
            billlastname: billingadress?.lastName,
            billphone: billingadress?.contactNo,
            billzipcode: billingadress?.zipCode,
          })
          .then((res) => {
            if (res && res.statusCode === 200 && res.data) {
              mixins.setStore("fulladdress", res.data, props);
              if (props.store.deliveryDetails) {
                history.push("/payment");
              } else {
                history.push("/delivery");
              }
            } else {
              if (res && res.errorMessages) {
                setAlertFail(res.errorMessages[0]);
                setTimeout(() => {
                  setAlertFail(null);
                }, 3000);
              } else {
                setAlertFail(res.message);
                setTimeout(() => {
                  setAlertFail(null);
                }, 3000);
              }
            }
          });
      } else {
        api
          .createShipingDetails({
            firstName: shippingAddress?.firstName,
            lastName: shippingAddress?.lastName,
            email: email,
            contactNo: shippingAddress?.contactNo,
            address1: shippingAddress?.address1 || "",
            address2: shippingAddress?.address2 || "",
            landmark: "",
            city: shippingAddress?.city,
            state: shippingAddress?.state,
            country: shippingAddress?.country,
            zipCode: shippingAddress?.zipCode,
            default: defaults,
            subscribe: +subscribe,
            // Billing
            billEditstatus: billingadress?.id ? editstatus : true,
            billId: billingadress?.id || null,
            billaddress1: billingadress?.address1 || "",
            billaddress2: billingadress?.address2 || "",
            billcity: billingadress?.city,
            billstate: billingadress?.state,
            billcountry: billingadress?.country,
            billfirstname: billingadress?.firstName,
            billlastname: billingadress?.lastName,
            billphone: billingadress?.contactNo,
            billzipcode: billingadress?.zipCode,
          })
          .then((res) => {
            if (res && res.statusCode === 200) {
              history.push("/delivery");
            } else {
              if (res && res.errorMessages) {
                setAlertFail(res.errorMessages[0]);
                setTimeout(() => {
                  setAlertFail(null);
                }, 3000);
              } else {
                setAlertFail(res.message);
                setTimeout(() => {
                  setAlertFail(null);
                }, 3000);
              }
            }
          });
      }
    }
    setTimeout(() => {
      setAlert(null);
    }, 2000);
  };
  // Subscribe Function
  const handleChangeRadioButton = (e) => {
    if (e.target.name === "subscribe") {
      setSubscribe(e.target.value);
    }
  };

  const initialRender = () => {
    api
      .getcountrylistbygst()
      .then((res) => {
        if (res.status == "success") {
          let tempcountries = res.data.map((item) => {
            return {
              ...item,
              sortnam: item?.sortnam?.trim() || "",
              phonecode: item[`"phonecode "`],
            };
          });
          setCountrylist(tempcountries);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  useEffect(() => {
    initialRender();
    if (props.store?.BillingAddress?.id) {
      let tempcountry =
        props.store?.BillingAddress?.country == "SG"
          ? "Singapore"
          : props.store?.BillingAddress?.country;
      setBillingaddress({
        ...billingAddress,

        ...props.store?.BillingAddress,
        country: tempcountry,
      });
      setSameadd(false);
    }
    if (props.store?.EditableAddress?.id) {
      let tempcountry =
        props.store?.EditableAddress?.country == "SG"
          ? "Singapore"
          : props.store?.EditableAddress?.country;
      setShippingaddress({
        ...shippingAddress,
        ...props.store?.EditableAddress,
        country: tempcountry,
      });
    }
  }, []);
  return (
    <>
      <div className="addresscard">
        {alert && (
          <Alert severity="error" variant="filled">
            <strong>{alert}</strong>
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="address-fields-container">
            <div className="address-form-input default-address">
              <span>Save as Default Address</span>
              <input
                type="checkbox"
                name="default"
                checked={defaults}
                onChange={(e) => {
                
                  setDefaults(e.target.checked);
                }}
              />
            </div>
            <Addaddresscard
              shipping={true}
              addressinfo={shippingAddress}
              countrylist={countrylist}
              setAddressinfo={setShippingaddress}
            />
            {/* Billing Address */}
            <div className="address-form-input default-address">
              <input
                type="checkbox"
                name="default"
                checked={sameAdd}
                onChange={(e) => {
                  
                  setSameadd(e.target.checked);
                }}
              />
              <span>My billing and shipping address are the same</span>
            </div>
            {sameAdd == false && (
              <Addaddresscard
                shipping={false}
                addressinfo={billingAddress}
                countrylist={countrylist}
                setAddressinfo={setBillingaddress}
              />
            )}

            {code ? null : (
              <div className="address-form-input-radio-container">
                <div className="address-form-input-radio">
                  <input
                    type="radio"
                    checked={subscribe == 1}
                    value={1}
                    name="subscribe"
                    onChange={(e) => handleChangeRadioButton(e)}
                  />
                  Join our members email to get 20% off your next order, and
                  other treats & offers throughout the year
                </div>
                <div className="address-form-input-radio">
                  <input
                    type="radio"
                    checked={subscribe == 0}
                    value={0}
                    name="subscribe"
                    onChange={(e) => handleChangeRadioButton(e)}
                  />
                  No Thanks
                </div>
              </div>
            )}
            <div className="address-form-input">
              <button className="buttoncontainer" type="submit">
                <img src={car} className="icon-png" /> Continue to Delivery
              </button>
            </div>
            <div className="address-form-input">
              <span>
                By clicking Continue, you agree to our{" "}
                <a target="_blank" href="/terms">
                  terms & conditions{" "}
                </a>{" "}
                and{" "}
                <a target="_blank" href="/privacy">
                  privacy policy.
                </a>
              </span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default connect(AddressCard);

import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/20.png";
import ImageCropper from "./image-cropper/ImageCropper";
import mixins from "../../../../app-services/mixins";
import Alertmodal from "../Alertmodal";
import whiteimg from "./../../../../assets/images/worldbook/white.png";

const Uploadtwo = (props) => {
  const {
    images,
    setImages,
    imagesassets,
    setImagesassets,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
    originalImage,
    setOriginalimage,
  } = props;

  const [alert, setAlert] = useState({
    image_two: "Maximum upload size: 5MB",
    image_three: "Maximum upload size: 5MB",
  });
  const nameRef = useRef();
  const [image1, setImage1] = useState({});
  const [image2, setImage2] = useState({});
  const [newalert, setNewalert] = useState(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!image1?.base64) {
      setAlert({
        ...alert,
        image_two: "Field is required.",
      });
      nameRef.current.focus();
    } else if (!image2?.base64) {
      setAlert({
        ...alert,
        image_three: "Field is required.",
      });
    } else {
      setImages({
        ...images,
        image_two: image1?.file,
        image_three: image2?.file,
      });
      setImagesassets({
        ...imagesassets,
        image_two: image1?.base64,
        image_three: image2?.base64,
      });
      handlePreinfoSave();
    }
  };
  const handleCropComplete = (base64, file) => {
    setImage1({
      base64: base64,
      file: file,
    });
  };
  const handleCropCompletethreeimage = (base64, file) => {
    setImage2({
      base64: base64,
      file: file,
    });
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert({
          image_two: "Maximum upload size: 5MB",
          image_three: "Maximum upload size: 5MB",
        });
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (imagesassets?.image_two) {
      setImage1({
        base64: imagesassets?.image_two,
        file: images?.image_two,
      });
    }
    if (imagesassets?.image_three) {
      setImage2({
        base64: imagesassets?.image_three,
        file: images?.image_three,
      });
    }
  }, []);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img
                  src={image1?.base64 || whiteimg}
                  className="uploaded_image_two"
                />
                <img
                  src={image2?.base64 || whiteimg}
                  className="uploaded_image_three"
                />
                <img src={titleimg} className="main_image_one" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <div className="mb-2">
                  <h4>PHOTO 2 </h4>
                  <p>Upload a photo of a cherished memory.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_two: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          setImage1({
                            base64: base64String,
                            file: file,
                          });
                        };
                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_two && (
                    <span
                      style={{
                        color: "red",
                      }}
                      className="mb-2"
                    >
                      {alert?.image_two}
                    </span>
                  )}
                  {originalImage?.image_two?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_two?.base64}
                          onCropComplete={handleCropComplete}
                          ratio={7 / 10}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h4>PHOTO 3</h4>
                  <p>Upload a picture of an unforgettable time.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_three: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          setImage2({
                            base64: base64String,
                            file: file,
                          });
                        };
                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_three && (
                    <span
                      style={{
                        color: "red",
                      }}
                      className="mb-2"
                    >
                      {alert?.image_three}
                    </span>
                  )}
                  {originalImage?.image_three?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_three?.base64}
                          onCropComplete={handleCropCompletethreeimage}
                          ratio={1}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alertmodal
        open={newalert}
        handleClose={() => {
          setNewalert(null);
        }}
      />
    </div>
  );
};

export default connect(Uploadtwo);

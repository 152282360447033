import React, { useEffect, useRef, useState } from "react";
import "./previewstyle.css";
import HTMLFlipBook from "react-pageflip";

const Worldbookview = (props) => {
  const bookRef = useRef(null);
  const { pages, answer } = props;
  const handleopenBook = (data) => {
    if (bookRef.current) {
      bookRef.current.pageFlip().turnToPage(data);
    }
  };
  useEffect(() => {
    handleopenBook(props?.currentopenpage);
  }, [props?.currentopenpage]);
  return (
    <div className="HandPrint Worldbookview">
      <div className="container">
        <div className="row no-gutters space">
          <div className="col-12">
            <div className="handprint">
              {pages.length > 0 && (
                <HTMLFlipBook
                  width={200}
                  height={200}
                  size="stretch"
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  className="demo-book"
                  ref={bookRef}
                >
                  {pages.map((pageContent, index) => {
                    const pageKey = Object.keys(pageContent)[0];

                    return (
                      <div
                        key={index}
                        className={`demoPage ${
                          index === 0
                            ? "front-page"
                            : index === pages.length - 1
                            ? "backcover"
                            : index % 2 === 1
                            ? "left-page"
                            : "right-page"
                        }`}
                        style={{ position: "relative" }}
                        dangerouslySetInnerHTML={{
                          __html: pageContent[pageKey],
                        }}
                      ></div>
                    );
                  })}
                </HTMLFlipBook>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Worldbookview);

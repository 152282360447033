import axios from "axios";

export default {
  // // saved books
  // savedBooks() {
  //   return this.handelAlert((res, rej, loadobj) => {
  //     axios
  //       .get(`${base}/api/subscription/Subscriptionlist`, {
  //         headers: {
  //           Authorization: `Bearer ${
  //             localStorage.getItem("token")
  //               ? localStorage.getItem("token")
  //               : token
  //           }`,
  //         },
  //       })
  //       .then((response) => {
  //         res(response.data);
  //       })
  //       .catch(rej);
  //   }, false);
  // },

  // orderHistory
  orderHistory() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/customerProfile/view`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  appLogout() {
    return this.handelAlert(
      (res, rej) => {
        axios
          .get(`${base}/api/user/logout`, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            localStorage.clear();
            mixins.setAccessToken();
            AppLayout.setStore("user", null);
            api
              .getSessionID()
              .then((sessionID) => {
                let localSessionID = localStorage.getItem("sessionID");
                localStorage.setItem("sessionID", localSessionID || sessionID);
                res(d.data);
              })
              .catch(() => res(d.data));
          })
          .catch(rej);
      },
      true,
      "Logging out please wait..."
    );
  },
  //get userprofile
  getuserProfile() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/user/profile`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  getProflieAddress(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/shippingAddress/userAddresses`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  updateAddress(data, id) {
    return this.handelAlert((resolve, reject, loadobj) => {
      axios
        .post(`${base}/api/shippingAddress/upsert/${id}`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((rej) => {
          reject(rej);
        });
    }, false);
  },

  //get user offers
  getUserOffers() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/promotion/listUserOffers`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
};

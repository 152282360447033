import React, { useState, useEffect } from "react";
import edit from "../../../assets/images/edit.png";
import search from "../../../assets/images/search.png";
import deleteicon from "../../../assets/images/delete.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import mixins from "../../../app-services/mixins";
export default connect((props) => {
  const [loading, setLoading] = useState("none");
  const history = useHistory();
  // console.log("id", props);
  const deleteCartitem = (id) => {
    setLoading("flex");
    api.deleteSubscriptionItem(id).then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200) {
        props.getAllSaveForLaterDetails();
        mixins.reFectchSavedBookDetails(props);
      }
    });
  };
  return (
    <div className="ProductCard row no-gutters card">
      {/* { console.log(props.item) } */}
      <div className="image-container">
        <img
          src={props.item?.Books?.fileData?.filePath}
          className="cart-image-container"
        />
        <img src={search} className="search-image-container" />
      </div>
      <div className="col">
        <div className="product_name">{props.item?.Books?.bookTitle}</div>
        <div className="Subtitle">Hard Cover</div>
        <div className="price">Price: $ {props.item?.Books?.bookPrice} SGD</div>
        <div style={{ display: loading }}>
          <CircularProgress color="success" />
        </div>
        <div className="button-container">
          <div
            className="delete-button"
            onClick={() => deleteCartitem(props.item.id)}
          >
            <img src={deleteicon} />
            Remove book
          </div>
          <div
            className="save-button"
            onClick={() => {
              //handprint, customcharacter, chapterbook, picturebook
              //console.log(props.item);
              mixins.setStore("cartdetails", props.item, props);
              let section = "chapter-book";
              let { customizedId, customBookType } = props.item.customBook;
              let { customBookId, bookId } = props.item;
              switch (customBookType) {
                case "chapterbook":
                  section = "chapter-book";
                  break;
                case "picturebook":
                  section = "picture-book";
                  break;
                case "handprint":
                  section = "handprint-book";
                  break;
                case "customcharacter":
                  section = "customcharacter-book";
                case "worldbook":
                  section = "world-book";
                  break;
              }
              history.push(`/book/${section}/${bookId}/${customizedId}`);
            }}
          >
            <img src={edit} />
            Edit Book
          </div>
        </div>
      </div>
    </div>
  );
});
